<template>
  <div>
    <b-dropdown
      size="sm"
      variant="button"
      toggle-class="custom-v-dropdown btn btn-light btn-sm btn-icon"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <i class="ki ki-bold-more-hor text-primary"></i>
      </template>
      <div class="navi navi-hover min-w-md-250px">
        <b-dropdown-text tag="div" class="navi-item">
          <a
            href="#"
            class="navi-link"
            @click.prevent
            v-b-modal.export-range-line-incident-engagement
          >
            <span class="navi-icon">
              <i class="flaticon2-file-2"></i>
            </span>
            <span class="navi-text">Export Engagement</span>
          </a>
        </b-dropdown-text>

        <b-dropdown-text tag="div" class="navi-item">
          <a
            href="#"
            class="navi-link"
            @click.prevent
            v-b-modal.export-competitor-summary
          >
            <span class="navi-icon">
              <i class="flaticon2-file-2"></i>
            </span>
            <span class="navi-text">Export Competitor Summary</span>
          </a>
        </b-dropdown-text>
      </div>
    </b-dropdown>

    <b-modal
      id="export-range-line-incident-engagement"
      ref="modal"
      title="Export engagement by date range"
    >
      <div class="mb-3">
        <label for="week_date_range">Week date range</label>
        <el-date-picker
          :editable="false"
          :clearable="true"
          class="w-100"
          v-model="weekDateRange"
          type="daterange"
          align="left"
          size="small"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          format="dd/MM/yyyy"
          value-format="timestamp"
        >
        </el-date-picker>
      </div>
      <div>
        <label for="overall_date_range">Overall date range</label>
        <el-date-picker
          :editable="false"
          :clearable="false"
          class="w-100"
          v-model="overallDateRange"
          type="daterange"
          align="left"
          size="small"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          format="dd/MM/yyyy"
          value-format="timestamp"
        >
        </el-date-picker>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            class="float-right"
            :class="{
              spinner: isExportEngagementDateRage,
              'spinner-right': isExportEngagementDateRage,
              'spinner-light': isExportEngagementDateRage,
              disabled: isExportEngagementDateRage
            }"
            @click="downloadRangeEngagementIncident"
          >
            Export
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      size="lg"
      id="export-competitor-summary"
      ref="modal"
      title="Export competitor summary"
      @ok.prevent="showSummary"
      ok-title="Get Summary"
      cancel-title="Close"
    >
      <div class="mb-3">
        <label for="week_date_range">Summary date range</label>
        <el-date-picker
          :editable="false"
          class="w-100"
          v-model="exportDateRange"
          type="daterange"
          align="left"
          size="small"
          start-placeholder="Start Date"
          end-placeholder="End Date"
          format="dd/MM/yyyy"
          value-format="timestamp"
        >
        </el-date-picker>
      </div>
      <div class="mt-3">
        <ExportSummary
          v-if="summaryData.length"
          :data="summaryData"
          :startDate="exportDateRange[0]"
          :endDate="exportDateRange[1]"
        ></ExportSummary>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FileSaver from "file-saver"
import moment from "moment"
import { LineIncidentLogsService } from "@/core/services"
import ExportSummary from "../../pages/line-incident/ExportSummary.vue"

export default {
  name: "LineIncidentDropdown",
  components: {
    ExportSummary
  },
  props: {
    cusId: String,
    competitorId: String
  },
  data() {
    return {
      isExportEngagementDateRage: false,
      overallDateRange: [
        moment()
          .startOf("isoWeek")
          .startOf("days")
          .valueOf(),
        moment()
          .endOf("isoWeek")
          .add(1, "days")
          .startOf("days")
          .valueOf()
      ],
      weekDateRange: null,
      exportDateRange: [
        moment()
          .startOf("isoWeek")
          .startOf("day")
          .valueOf(),
        moment()
          .endOf("isoWeek")
          .endOf("day")
          .valueOf()
      ],
      summaryData: []
    }
  },
  methods: {
    async downloadRangeEngagementIncident(e) {
      this.isExportEngagementDateRage = true
      e.preventDefault()
      let blob = await LineIncidentLogsService.downloadRangeEngagement({
        cusId: this.cusId,
        competitorId: this.competitorId,
        overallFromDate: moment(this.overallDateRange[0])
          .startOf("day")
          .valueOf(),
        overallToDate: moment(this.overallDateRange[1])
          .endOf("day")
          .valueOf(),
        weekFromDate: this.weekDateRange
          ? moment(this.weekDateRange[0])
              .startOf("day")
              .valueOf()
          : 0,
        weekToDate: this.weekDateRange
          ? moment(this.weekDateRange[1])
              .endOf("day")
              .valueOf()
          : 0
      })
      FileSaver.saveAs(blob.blob, decodeURIComponent(blob.filename))
      this.isExportEngagementDateRage = false
    },
    async showSummary() {
      let res = await LineIncidentLogsService.exportSummary({
        cusId: this.cusId,
        competitorId: this.competitorId,
        startDate: this.exportDateRange
          ? moment(this.exportDateRange[0])
              .startOf("day")
              .valueOf()
          : 0,
        endDate: this.exportDateRange
          ? moment(this.exportDateRange[1])
              .endOf("day")
              .valueOf()
          : 0
      })

      if (!res.error) {
        let data = res.data.data
        data = data.map(v => {
          if (!v.competitor) {
            let brand = v.links[0].brand
            v.competitor = brand
            v.competitor.text = brand.name
          }
          return v
        })

        this.summaryData = data
      } else {
        this.$root.$bvToast.toast("Get summary error", {
          title: `Error`,
          variant: "error",
          solid: true
        })
      }
    }
  }
}
</script>

<style></style>
