<template>
  <div id="incident-record">
    <b-card
      no-body
      class="border card-custom gutter-b"
      :style="{
        'border-color':
          (alertCompetitorSelect.color || alertBrandSelect.brandColor) +
          ' !important'
      }"
    >
      <b-card-header
        :style="{
          'background-color':
            alertCompetitorSelect.color || alertBrandSelect.brandColor
        }"
      >
        <div class="card-title">
          <h6 class="mb-0 text-white">Incident Records</h6>
        </div>
        <div class="card-toolbar">
          <LineIncidentDropdown
            :cus-id="selectedBrand.cusId"
            :competitor-id="competitorId"
          ></LineIncidentDropdown>
          <button-expand screen-id="incident-record"></button-expand>
        </div>
      </b-card-header>

      <b-card-body>
        <b-row class="mb-3">
          <b-col sm="3">
            <b-form-select
              size="sm"
              v-model="paging.perPage"
              :options="[10, 25, 50, 100]"
            >
            </b-form-select>
          </b-col>
          <b-col sm="6" offset-sm="3">
            <div class="d-flex flex-row" style="column-gap: 5px;">
              <b-select
                :options="fields"
                size="sm"
                v-model="fieldSelected"
                id="fields"
              ></b-select>
              <b-form-input
                size="sm"
                type="text"
                placeholder="Search"
                v-model.trim="logSearch"
              ></b-form-input>
            </div>
          </b-col>
        </b-row>

        <b-table
          bordered
          responsive
          :items="incidentLogDatas"
          :fields="incidentLogFields"
          show-empty
          :busy="!isIncidentLogLoaded"
        >
          <template v-slot:table-busy>
            <div class="text-danger my-2">
              <b-spinner class="align-middle mx-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:head(created_at)="data">
            <div style="width:120px">{{ data.label }}</div>
          </template>

          <template v-slot:head(updated_at)="data">
            <div style="width:120px">{{ data.label }}</div>
          </template>
          <template v-slot:empty>
            <center>No data</center>
          </template>

          <template v-slot:cell(no)="row">
            {{ iterationLink + row.index + 1 }}
          </template>

          <template v-slot:cell(text)="row">
            <div style="width:max-content;max-width:400px;min-width:300px">
              <b-form-textarea
                type="text"
                size="lg"
                :value="decodeHTML(row.item.text)"
                readonly
                rows="5"
              ></b-form-textarea>
            </div>
          </template>

          <template v-slot:cell(link)="row">
            <a :href="row.item.link" target="_blank" rel="noopener noreferrer"
              ><i class="flaticon2-graphic-design"></i
            ></a>
          </template>

          <template v-slot:cell(isActive)="row">
            <center>
              <badge-status :active="row.item.isActive"></badge-status>
            </center>
          </template>

          <template v-slot:cell(brand)="row">
            <span v-if="row.item.competitor">{{
              row.item.competitor.text
            }}</span>
            <span v-else>{{ row.item.brand.name }}</span>
          </template>

          <template v-slot:cell(count)="row">
            <div>
              <i class="mr-2 flaticon-like"></i>{{ row.item.count.like }}
            </div>
            <div>
              <i class="mr-2 flaticon-comment"></i>{{ row.item.count.comment }}
            </div>
            <div>
              <i class="mr-2 flaticon-share"></i>{{ row.item.count.retweet }}
            </div>
            <div>
              <i class="mr-2 flaticon-eye"></i>{{ row.item.count.view }}
            </div>
          </template>

          <template v-slot:cell(intention)="row">
            <b-form-select
              :options="intentionOpts"
              style="width:150px"
              @change="changeIntention(row.item.logId, $event)"
              :value="row.item.intention"
            >
            </b-form-select>
          </template>

          <template v-slot:cell(alertLabel)="row">
            <b-form-select
              :options="[
                { text: 'Default', value: 'default' },
                { text: 'Level 0: Buying Signal', value: 'level0' },
                { text: 'Level 1: Low Crisis Level', value: 'level1' },
                {
                  text: 'Level 2: Moderate Crisis Level',
                  value: 'level2'
                },
                { text: 'Level 3: Severe Crisis Level', value: 'level3' },
                { text: 'Level 4: Catastrophic Crisis Level', value: 'level4' }
              ]"
              style="width:150px"
              @change="changeLabel(row.item.logId, $event)"
              :value="row.item.alertLabel"
            >
            </b-form-select>
          </template>

          <template v-slot:cell(actions)="row">
            <div style="width:150px">
              <button-delete
                v-b-modal.delete-log
                @click="selectedIncidentLog = row.item"
              >
                <i class="flaticon2-trash icon-md"></i>
              </button-delete>

              <b-button
                size="sm"
                variant="light"
                class="btn-icon mx-1"
                @click="resend(row.index)"
                :disabled="row.item.isResend"
              >
                <span
                  v-if="row.item.isResend"
                  class="spinner-border spinner-border-sm text-primary"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-else class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="media/svg/logos/line-logo.svg"></inline-svg>
                </span>
              </b-button>
            </div>
          </template>
        </b-table>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <span v-if="paging"
            >{{ paging.totalRows | number_format }} records</span
          >
          <b-pagination
            v-if="paging.numberOfPages"
            v-model="paging.currentPage"
            :total-rows="paging.totalRows"
            align="right"
            :number-of-pages="paging.numberOfPages"
            :per-page="paging.perPage"
            use-router
            @change="changeIncidentLog"
          ></b-pagination>
        </div>
      </b-card-body>
    </b-card>

    <confirm-delete
      modal-id="delete-log"
      :remove-fn="removeIncidentLog"
      @ok="changeIncidentLog(1)"
    ></confirm-delete>
  </div>
</template>

<script>
import { LineIncidentLogsService } from "@/core/services"
import moment from "moment"
import _ from "lodash"
import LineIncidentDropdown from "@/view/content/custom/LineIncidentDropdown.vue"
import { mapGetters } from "vuex"
import HtmlEntities from "../../../core/plugins/html-entities"

export default {
  name: "IncidentRecords",
  components: { LineIncidentDropdown },
  props: {
    selectedBrand: {
      type: Object,
      required: true
    },
    competitorId: String
  },
  data() {
    return {
      fieldSelected: "all",
      fields: [
        { value: "all", text: "All Field" },
        { value: "username", text: "Username" },
        { value: "link", text: "Link" },
        { value: "source", text: "Source" },
        { value: "text", text: "Text" }
      ],
      isIncidentLogLoaded: false,
      selectedIncidentLog: null,
      paging: {
        perPage: 10,
        currentPage: 1,
        totalRows: 0,
        numberOfPages: 0
      },

      incidentLogDatas: [],
      incidentLogFields: [
        {
          key: "no",
          label: "#",
          class: "text-center"
        },

        { key: "brand", label: "Competitor" },
        {
          key: "created_at",
          label: "Created Date"
        },
        {
          key: "updated_at",
          label: "Sent Date"
        },
        {
          key: "source",
          label: "Source"
        },
        { key: "username", label: "Username" },
        {
          key: "text",
          label: "Text"
        },
        {
          key: "count",
          label: "Engagement"
        },
        {
          key: "link",
          label: "Link"
        },
        {
          key: "intention",
          label: "Intention"
        },
        { key: "alertLabel", label: "Alert Label" },
        {
          key: "actions",
          label: "Action",
          class: "text-center"
        }
      ],
      logSearch: ""
    }
  },
  mounted() {
    this.paging.currentPage = Number(this.$route.query.page) || 1
    this.getIncidentLogs()
  },
  computed: {
    intentionOpts() {
      return this.alertBrandSelect ? this.alertBrandSelect.intention : []
    },
    iteration() {
      return this.paging.perPage * (this.paging.currentPage - 1)
    },
    iterationLink() {
      return this.paging.perPage * (this.paging.currentPage - 1)
    },
    ...mapGetters(["shareData"]),
    alertBrandSelect() {
      return this.shareData("alertBrandSelect")
    },
    alertCompetitorSelect() {
      return this.shareData("alertCompetitorSelect")
    }
  },
  watch: {
    topicSearch: _.debounce(function() {
      this.paging.currentPage = 1
      this.getTopics()
    }, 500),
    logSearch: _.debounce(function() {
      this.paging.currentPage = 1
      this.getIncidentLogs()
    }, 500),

    fieldSelected: _.debounce(function() {
      if (this.logSearch) {
        this.paging.currentPage = 1
        this.getIncidentLogs()
      }
    }, 500),

    "paging.perPage"() {
      this.paging.currentPage = 1
      this.getIncidentLogs()
    }
  },
  methods: {
    resend(itemIndex) {
      let newData = [...this.incidentLogDatas]
      if (newData[itemIndex].isResend) return

      newData[itemIndex].isResend = true
      this.incidentLogDatas = _.cloneDeep(newData)

      LineIncidentLogsService.resend(newData[itemIndex].logId)
        .then(response => {
          if (!response.error) {
            this.$root.$bvToast.toast("Resend incident log success", {
              title: `Success`,
              variant: "success",
              solid: true
            })
          } else {
            this.$root.$bvToast.toast("Resend incident log error", {
              title: `Error`,
              variant: "error",
              solid: true
            })
          }
        })
        .finally(() => {
          newData[itemIndex].isResend = false
          this.incidentLogDatas = _.cloneDeep(newData)
        })
    },
    decodeHTML(str) {
      return HtmlEntities.decode(str)
    },
    resetIncidentLog() {
      this.selectedIncidentLog = null
    },
    changeIncidentLog(page) {
      this.paging.currentPage = page
      this.getIncidentLogs()
    },
    getIncidentLogs() {
      let q = encodeURIComponent(`${this.fieldSelected}:${this.logSearch}`)

      this.isIncidentLogLoaded = false
      LineIncidentLogsService.get({
        cusId: this.selectedBrand.cusId,
        competitorId: this.competitorId,
        offset: this.paging.perPage * (this.paging.currentPage - 1),
        limit: this.paging.perPage,
        q: q
      }).then(incidentLogs => {
        this.paging.totalRows = incidentLogs.total
        this.paging.numberOfPages = Math.ceil(
          this.paging.totalRows / this.paging.perPage
        )
        let incidentLogDatas = incidentLogs.results || []
        this.incidentLogDatas = incidentLogDatas.map(v => {
          return {
            ...v,
            created_at: moment(v.created_at).format("DD/MM/YYYY HH:mm"),
            updated_at: moment(v.updated_at).format("DD/MM/YYYY HH:mm")
          }
        })
        this.isIncidentLogLoaded = true
      })
    },
    removeIncidentLog() {
      return LineIncidentLogsService.delete(this.selectedIncidentLog._id)
    },
    async changeIntention(logId, intention) {
      let response = await LineIncidentLogsService.update(logId, {
        intention
      })
      if (!response.error) {
        this.$root.$bvToast.toast("Update incident log success", {
          title: `Success`,
          variant: "success",
          solid: true
        })
      } else {
        this.$root.$bvToast.toast("Update incident log error", {
          title: `Error`,
          variant: "error",
          solid: true
        })
      }
    },
    async changeLabel(logId, alertLabel) {
      let response = await LineIncidentLogsService.update(logId, {
        alertLabel
      })
      if (!response.error) {
        this.$root.$bvToast.toast("Update alert label success", {
          title: `Success`,
          variant: "success",
          solid: true
        })
      } else {
        this.$root.$bvToast.toast("Update alert label error", {
          title: `Error`,
          variant: "error",
          solid: true
        })
      }
    }
  }
}
</script>

<style scoped>
.bg-orange {
  background-color: orange;
}
</style>
