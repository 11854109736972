<template>
  <div
    v-if="data"
    style="height:300px;overflow-y: auto;"
    class="mt-3 position-relative"
  >
    <div class="copy-text">
      <div class="position-fixed">
        <span class="btn btn-bg-light" @click="copyElementToClipboard">{{
          copyText
        }}</span>
      </div>
    </div>
    <div id="export_summary">
      <div>สรุปประเด็น Competitors จาก Facebook Group</div>
      <div class="mb-5">{{ dateRange }}<br /></div>

      <div v-for="competitor in data" :key="competitor._id">
        <div>{{ competitor.competitor.text }}</div>
        <div
          v-for="(linkInfo, index) in competitor.links"
          :key="linkInfo.linkId"
        >
          <div>{{ ++index }}. Facebook Group - {{ linkInfo.pageName }}</div>
          <div class="mb-5">
            [{{ convertAlertLabel(linkInfo.alertLabel) }}]<br />
          </div>

          <div>{{ linkInfo.text }}</div>
          <div>
            <a :href="linkInfo.link" target="_blank">{{ linkInfo.link }}</a>
          </div>

          <div>วันที่ {{ convertToDateThaiTxt(new Date(linkInfo.date)) }}</div>
          <div class="mb-5">
            <div>Like: {{ linkInfo.count.like }}</div>
            <div>Share: {{ linkInfo.count.share }}</div>
            <div>Comment: {{ linkInfo.count.comment }}<br /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExportSummary",
  props: {
    data: null, // [{"group":"xxx",links:[]}]
    startDate: Number,
    endDate: Number
  },
  data() {
    return {
      copyText: "Copy"
    }
  },
  computed: {
    dateRange() {
      let { startDate, endDate } = this
      return this.convertToDateRangeThai(new Date(startDate), new Date(endDate))
    }
  },
  methods: {
    copyElementToClipboard() {
      let copyText = document.getElementById("export_summary")
      navigator.clipboard.writeText(copyText.innerText)
      this.copyText = "Copied"
      setTimeout(() => {
        this.copyText = "Copy"
      }, 1000)
    },
    convertAlertLabel(value) {
      let label = [
        { text: "Default", value: "default" },
        { text: "Green", value: "green" },
        {
          text: "Yellow - Negative information",
          value: "yellow-negative-information"
        },
        {
          text: "Yellow - Soft Complaints",
          value: "yellow-soft-complaints"
        },

        { text: "Orange", value: "orange" },
        { text: "Red", value: "red" }
      ].filter(v => v.value === value)[0]
      return label ? label.text : ""
    },
    convertToDateThaiTxt(date) {
      let thaiMonth = [
        "",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ]
      return `${date.getDate()} ${
        thaiMonth[date.getMonth() + 1]
      } ${date.getFullYear() + 543}`
    },

    convertToDateThai(date) {
      let thaiMonth = [
        "",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม"
      ]
      return {
        date: date.getDate(),
        month: thaiMonth[date.getMonth() + 1],
        year: date.getFullYear() + 543
      }
    },
    convertToDateRangeThai(start, end) {
      let startDate = this.convertToDateThai(start)
      let endDate = this.convertToDateThai(end)

      if (
        startDate.month === endDate.month &&
        startDate.year === endDate.year
      ) {
        return `วันที่ ${startDate.date} - ${endDate.date} ${startDate.month} ${startDate.year}`
      }
      return `วันที่ ${startDate.date} ${startDate.month} ${startDate.year} - ${endDate.date} ${endDate.month} ${endDate.year}`
    }
  }
}
</script>

<style scoped>
.copy-text {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 24px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
