<template>
  <div class="mt-5 mb-5">
    <b-row>
      <b-col>
        <label for="cusId">Brand: </label>
        <b-form-group>
          <draggable
            v-model="brands"
            @start="drag = true"
            @end="drag = false"
            @change="onDrag"
          >
            <transition-group>
              <!-- <div v-for="element in myArray" :key="element.id">{{element.name}}</div> -->
              <b-button
                class="text-white m-1"
                :style="getActiveBrandStyle(brand)"
                v-for="brand in brands"
                :key="brand.cusId"
                @click="changeCusId(brand.cusId)"
              >
                {{ brand.name }}
              </b-button>
            </transition-group>
          </draggable>

          <!-- <b-button
            class="text-white m-1"
            :style="getActiveBrandStyle(brand)"
            v-for="brand in brands"
            :key="brand.cusId"
            @click="changeCusId(brand.cusId)"
          >
            {{ brand.name }}
          </b-button> -->
        </b-form-group>
        <!-- <b-form-select
          id="cusId"
          v-model="cusId"
          :options="brands"
          value-field="cusId"
          text-field="name"
        ></b-form-select> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="competitorId">Competitor: </label>

        <b-form-group>
          <b-button
            class="text-white m-1"
            :style="getActiveCompetitorStyle(competitor)"
            v-for="competitor in competitors"
            :key="competitor.competitorId"
            @click="changeCompetitorId(competitor.competitorId)"
          >
            {{ competitor.text }}
          </b-button>
        </b-form-group>

        <!-- <b-form-select
          id="competitorId"
          v-model="competitorId"
          :options="competitors"
          value-field="competitorId"
          text-field="text"
          :disabled="selectedBrand ? false : true"
        ></b-form-select> -->
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col md="6">
        <LineIncidentResolve
          :selected-brand="selectedBrand"
          :competitor-id="competitorId"
          v-if="selectedBrand"
          @submit="reloadRecord"
        ></LineIncidentResolve>
      </b-col>
      <b-col md="6">
        <IncidentRecord
          :selected-brand="selectedBrand"
          :competitor-id="competitorId"
          v-if="isReady"
        ></IncidentRecord>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import { SET_SHARE_DATA } from "@/core/services/store/share.module"
import LineIncidentResolve from "./LineIncidentResolve.vue"
import { CustomerService, CompetitorService } from "@/core/services"
import IncidentRecord from "./IncidentRecord.vue"
import { mapGetters } from "vuex"
import _ from "lodash"
import draggable from "vuedraggable"

export default {
  components: {
    LineIncidentResolve,
    IncidentRecord,
    draggable
  },
  data() {
    return {
      isReady: false,
      cusId: null,
      competitorId: null,
      selectedBrand: null,
      brands: [],
      competitors: []
    }
  },
  computed: {
    ...mapGetters(["shareData"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "LINE Incidents" }])
    CustomerService.get({ limit: 0, order_by: { orderNumber: 1 } }).then(
      response => {
        this.brands = response.results

        this.$store.dispatch(SET_SHARE_DATA, {
          alertCompetitorSelectId: null,
          alertCompetitorSelect: {},
          isReset: true
        })

        if (this.$route.query.cusId) {
          this.cusId = this.$route.query.cusId
        } else {
          this.cusId =
            this.shareData("alertBrandSelectId") || this.brands[0].cusId
        }
      }
    )
  },
  watch: {
    async cusId(selectedCusId) {
      this.$store.dispatch(SET_SHARE_DATA, {
        alertBrandSelectId: selectedCusId
      })

      this.isReady = false
      this.selectedBrand = null
      this.selectedBrand = this.brands.filter(v => v.cusId === selectedCusId)[0]

      let alertBrandSelect = _.pick(this.selectedBrand, [
        "cusId",
        "name",
        "brandColor",
        "competitors",
        "intention",
        "statuses"
      ])

      this.$store.dispatch(SET_SHARE_DATA, {
        alertBrandSelect,
        alertCompetitorSelect: {},
        isReset: true
      })

      await this.getCompetitors().then(() => {
        this.$nextTick(() => {
          this.isReady = true
          this.competitorId = null
          // let competitorExist = this.competitors.filter(
          //   (v) => v.competitorId === this.shareData("alertCompetitorSelectId")
          // ).length
          // if (competitorExist) {
          //   this.competitorId = this.shareData("alertCompetitorSelectId")
          // } else {
          //   this.competitorId = null
          // }
        })
      })
    },
    competitorId(newValue) {
      this.isReady = false
      this.$nextTick(() => {
        this.isReady = true
        let competitorExist = this.competitors.filter(
          v => v.competitorId === newValue
        )[0]

        this.$store.dispatch(SET_SHARE_DATA, {
          alertCompetitorSelect: competitorExist
        })
      })
    }
  },
  methods: {
    getActiveBrandStyle(brand) {
      if (brand.cusId === this.cusId) {
        return { "background-color": brand.brandColor }
      }
      return { "background-color": "rgba(42,34,40,0.2)" }
    },
    getActiveCompetitorStyle(competitor) {
      if (competitor.competitorId === this.competitorId) {
        return { "background-color": competitor.color }
      }
      return { "background-color": "rgba(42,34,40,0.2)" }
    },

    changeCompetitorId(competitorId) {
      this.competitorId = competitorId
    },
    changeCusId(cusId) {
      this.cusId = cusId
    },
    getCompetitors() {
      return CompetitorService.get(this.cusId, {
        limit: 0,
        offest: 0,
        order_by: { _id: 1 }
      }).then(response => {
        this.competitors = [
          {
            text: this.selectedBrand.name,
            competitorId: null,
            color: this.selectedBrand.brandColor
          }
        ].concat(response.results || [])
      })
    },
    reloadRecord() {
      this.isReady = false
      this.$nextTick(() => {
        this.isReady = true
      })
    },
    onDrag() {
      for (let [order, brand] of this.brands.entries()) {
        CustomerService.update({
          cusId: brand.cusId,
          orderNumber: order + 1
        })
      }
    }
  }
}
</script>

<style></style>
